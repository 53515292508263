import React, { useState, useEffect } from "react";
import freestyle from "../assets/image/freestyle_logo.png";
import tick1 from "../assets/image/tick1.png";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Checkbox,
  Input,
  Steps,
  DatePicker,
  Select,
  Form,
  Modal,
} from "antd";
import axios from "axios";
import "../pages/special/special.css";
import { connect } from 'react-redux';
import { checkExistingEmailNPhone } from "../services/email.service";

const { Step } = Steps;
const { Option } = Select;

const GroupEducation = ({ storecode, updateAddress }) => {
  const navigate = useNavigate();

  const [current, setCurrent] = useState(0);
  const [part, setPart] = useState(1);

  const [gender, setGender] = useState("");
  const [errorGender, setErrorGender] = useState("");

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const [dob, setDob] = useState("");
  const [doberror, setDobError] = useState("");

  const [salution, setSalution] = useState("");
  const [errorsalution, setErrorSalution] = useState("");

  const [firstName, setFirstName] = useState("");
  const [errorfirstname, setErrorFirstName] = useState("");

  const [lastName, setLastName] = useState("");
  const [errorlastname, setErrorlastName] = useState("");

  const [email, setEmail] = useState("");
  const [errorEmail, setErrorEmail] = useState("");

  const [handphone, setHandphone] = useState("");
  const [errorphone, setErrorphone] = useState("");

  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [address3, setAddress3] = useState("");

  const [diabetesType /*, setdiabetesType*/] = useState(["Type 1", "Type 2"]);
  const [diabetesTypeError, setdiabetesTypeError] = useState("");
  const [isSelected, setIsSelected] = useState("");

  const [InsulinBasalBolus, setInsulinBasalBolus] = useState(false);
  const [InsulinPremix, setInsulinPremix] = useState(false);
  const [InsulinBasalOnly, setInsulinBasalOnly] = useState(false);
  const [InsulinPump, setInsulinPump] = useState(false);
  const [OralMedication, setOralMedication] = useState(false);
  const [InsulinOralMedication, setInsulinOralMedication] = useState(false);
  const [OthersClick, setOthersClick] = useState(false);
  const [Others, setOthers] = useState("");
  const [diabetesTreatmentError, setdiabetesTreatmentError] = useState("");

  const [hospitalName, sethospitalName] = useState("");
  const [hospitalNameError, sethospitalNameError] = useState("");

  const [doctorName, setdoctorName] = useState("");
  const [doctorNameError, setdoctorNameError] = useState("");

  const [modal, setModal] = useState(false);
  const [tncmodal, setTncModal] = useState(false);
  const [initialModal, setInitialModal] = useState(storecode && storecode.includes("FSLPhy") ? true : false);

  const [storelist, setStoreList] = useState([]);

  const [checked, setChecked] = useState(true);

  useEffect(() => {
    axios.get("/api/auth/getStorelist")
      .then((res) => {
        if (storecode && storecode.includes("FSLPhy")) {
          let channel = res.data.data.filter(store => store.storecode === storecode)[0]
          if (channel) {
            sethospitalName(channel.channel)
          }
        }
        setStoreList(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setError(err.response.data.error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const next = async () => {
    setLoading(true);
    setError("")
    setErrorGender("");
    setDobError("");
    if (!gender) {
      setErrorGender("Please choose your gender");
      setLoading(false);
    } else if (new Date().getFullYear() - new Date(dob).getFullYear() < 18) {
      setDobError("Only 18 years old and above can join the campaign");
      setLoading(false);
    } else if (!dob) {
      setDobError("Please enter your Day Of Birth");
      setLoading(false);
    } else if (
      !errorsalution &&
      !errorfirstname &&
      !errorlastname &&
      !errorEmail &&
      !errorphone &&
      salution &&
      firstName &&
      lastName &&
      gender &&
      handphone &&
      address1 &&
      dob
    ) {

      const emailNPhoneExist = await checkExistingEmailNPhone({email, phone: handphone})
      if(!emailNPhoneExist.exist){
        setCurrent(current + 1);
        setPart(part + 1);
        setLoading(false);
      }
      else {
        if(emailNPhoneExist.exist){
          setError(emailNPhoneExist.message); 
        }
        setLoading(false);
      }
    } else {
      setLoading(false);
      setError("Please fill up all fields and enter with correct format");
    }
  };

  const done = () => {
    setdiabetesTypeError("");
    setdiabetesTreatmentError("");
    sethospitalNameError("");
    setdoctorNameError("");

    if (!isSelected) {
      setdiabetesTypeError("Please select one of the diabetes type");
    } else if (
      !InsulinBasalBolus &&
      !InsulinPremix &&
      !InsulinBasalOnly &&
      !InsulinPump &&
      !OralMedication &&
      !InsulinOralMedication &&
      !Others
    ) {
      setdiabetesTreatmentError(
        "Please select at least one of the diabetes type"
      );
    } else if (!hospitalName) {
      sethospitalNameError("Please insert the Hospital Name");
    } else if (!doctorName) {
      setdoctorNameError("Plese enter the Doctor Name");
    } else {
      setModal(true);
    }
  };

  const clickbox = () => {
    setError("");
    setLoading(true);

    axios
      .post("api/auth/register", {
        salution,
        firstName,
        lastName,
        gender,
        dob,
        email,
        handphone,
        address1,
        address2,
        address3,
        diabetesType: isSelected,
        hospitalName,
        doctorName,
        InsulinBasalBolus,
        InsulinPremix,
        InsulinBasalOnly,
        InsulinPump,
        OralMedication,
        InsulinOralMedication,
        Others,
        storecode,
      })
      .then((res) => {
        setLoading(false);
        if (storecode && storecode.includes("FSLPhy")) {
          updateAddress(address1 + " " + address2 + " " + address3)
          navigate("/startRedeem");
        } else {
          navigate("/journey");
        }
      })
      .catch((err) => {
        setLoading(false);
        setError(err.response.data.error);
      });
  };

  const isEmail = (e) => {
    setEmail(e.target.value);

    // eslint-disable-next-line no-useless-escape
    let regEmail = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;
    if (!regEmail.test(e.target.value)) {
      setErrorEmail("Invalid email format");
    } else {
      setErrorEmail("");
    }
    if (!e.target.value) {
      setErrorEmail("");
    }
  };

  const isfirstName = (e) => {
    var regex = /^[a-zA-Z ]+( [a-zA-Z ]+)*$/;
    // /^[a-zA-Z]+(?:\s[a-zA-Z]+)+$/;
    setFirstName(e.target.value);
    if (!regex.test(e.target.value)) {
      setErrorFirstName("Invalid name format");
    } else {
      setErrorFirstName("");
    }
    if (!e.target.value) {
      setErrorFirstName("");
    }
  };

  const islastName = (e) => {
    var regex = /^[a-zA-Z ]+( [a-zA-Z ]+)*$/;
    // /^[a-zA-Z]+(?:\s[a-zA-Z]+)+$/;
    setLastName(e.target.value);
    if (!regex.test(e.target.value)) {
      setErrorlastName("Invalid name format");
    } else {
      setErrorlastName("");
    }
    if (!e.target.value) {
      setErrorlastName("");
    }
  };

  const isSalution = (e) => {
    var regex = /^[a-zA-Z ]+( [a-zA-Z ]+)*$/;
    // /^[a-zA-Z]+(?:\s[a-zA-Z]+)+$/;
    setSalution(e.target.value);
    if (!regex.test(e.target.value)) {
      setErrorSalution("Invalid salution format");
    } else {
      setErrorSalution("");
    }
    if (!e.target.value) {
      setErrorSalution("");
    }
  };

  const isNumber = (e) => {
    setHandphone(e.target.value);

    let regNumber = /^(01)[0-46-9]([0-9]){7,8}$/;
    if (!regNumber.test(e.target.value)) {
      setErrorphone(
        'Invalid phone number format. Supported phone number format is "0123456789"'
      );
    } else {
      setErrorphone("");
    }
    if (!e.target.value) {
      setErrorphone("");
    }
  };

  const handleGender = (value) => {
    setGender(value);
  };

  const handleHospital = (value) => {
    sethospitalName(value);
  };

  const isDob = (date, dateString) => {
    setDobError("");
    if (new Date().getFullYear() - new Date(dateString).getFullYear() < 18) {
      setDobError("Only 18 years old and above can join the campaign");
      setDob("");
    } else {
      setDob(dateString);
    }
  };

  const onChange = (e) => {
    if (e.target.checked) {
      setIsSelected(e.target.name);
    } else {
      setIsSelected("");
    }
  };

  return (
    <div>
      <div className="header-icon-position">
        <img src={freestyle} alt="" className="header-icon-size" />
      </div>
      <div className="header">{storecode && storecode.includes("FSLPhy") ? "Education Session" : "Group Education"}</div>
      <div className="malaysia-container">
        <div className="malaysia">Country: Malaysia</div>
        <div>
          <Steps
            current={current}
            size="small"
            direction="horizontal"
            labelPlacement="vertical"
            className="steps-force">
            <Step current="1" title="Personal Information" />
            <Step current="2" title="Treatment Information" />
          </Steps>
        </div>{" "}
      </div>
      {part === 1 ? (
        <div>
          <div className="personal-info">PERSONAL INFORMATION</div>
          <div className="hinttext">
            Mandatory fields are marked with<span className="red">*</span>
          </div>
          <Form onFinish={next}>
            <div className="form-container">
              <div>
                <div className="question-tiitle">
                  Salutation<span className="red">*</span>
                </div>
                <Input
                  className="question-input"
                  value={salution}
                  onChange={(e) => isSalution(e)}
                  required
                  placeholder="Example: Mr / Mrs"></Input>
                <div className="errormessage">
                  {errorsalution ? errorsalution : null}
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ width: "45%" }}>
                  <div className="question-tiitle">
                    First Name<span className="red">*</span>
                  </div>
                  <Input
                    className="question-input"
                    value={firstName}
                    onChange={(e) => isfirstName(e)}
                    required></Input>
                  <div className="errormessage">
                    {errorfirstname ? errorfirstname : null}
                  </div>
                </div>
                <div style={{ width: "45%" }}>
                  <div className="question-tiitle">
                    Last Name<span className="red">*</span>
                  </div>
                  <Input
                    className="question-input"
                    value={lastName}
                    onChange={(e) => islastName(e)}
                    required></Input>
                  <div className="errormessage">
                    {" "}
                    {errorlastname ? errorlastname : null}
                  </div>
                </div>
              </div>
              <div>
                <div className="question-tiitle">
                  Gender<span className="red">*</span>
                  <Select onChange={handleGender} value={gender} required>
                    <Option value="Male">Male</Option>
                    <Option value="Female">Female</Option>
                  </Select>
                </div>
                <div className="errormessage">
                  {errorGender ? errorGender : null}
                </div>
              </div>
              <div>
                <div className="question-tiitle">
                  Date of Birth (YYYY-MM-DD)<span className="red">*</span>
                </div>
                <DatePicker
                  className="question-input"
                  required
                  onChange={isDob}></DatePicker>
                <div className="errormessage">{doberror ? doberror : null}</div>
              </div>
              <div>
                <div className="question-tiitle">
                  Email
                </div>
                <Input
                  className="question-input"
                  onChange={(e) => isEmail(e)}
                  value={email}
                  placeholder="Example: abc@gmail.com"></Input>
                <div className="errormessage">
                  {errorEmail ? errorEmail : null}
                </div>
              </div>
              <div>
                <div className="question-tiitle">
                  Handphone
                  <span className="red">*</span>
                </div>
                <Input
                  className="question-input"
                  onChange={(e) => isNumber(e)}
                  value={handphone}
                  required
                  placeholder="Example: 0123456789"></Input>
                <div className="errormessage">
                  {errorphone ? errorphone : null}
                </div>
              </div>
              <div>
                <div className="question-tiitle">
                  Address<span className="red">*</span>
                </div>
                <Input
                  className="question-input"
                  required
                  value={address1}
                  onChange={(e) => setAddress1(e.target.value)}></Input>
                <Input
                  className="question-input"
                  value={address2}
                  onChange={(e) => setAddress2(e.target.value)}></Input>
                <Input
                  className="question-input"
                  value={address3}
                  onChange={(e) => setAddress3(e.target.value)}></Input>
              </div>
              <div className="ok-button-container">
                <div className="errormessage">{error ? error : null}</div>
                <Button
                  className="ok-button"
                  loading={loading}
                  htmlType="submit">
                  NEXT
                </Button>
              </div>
            </div>
          </Form>
        </div>
      ) : part === 2 ? (
        <div>
          <div className="personal-info">TREATMENT INFORMATION</div>
          <div className="hinttext">
            Mandatory fields are marked with<span className="red">*</span>
          </div>
          <Form onFinish={done}>
            <div className="form-container">
              <div>
                <div className="question-tiitle">
                  Diabetes Type<span className="red">*</span>
                </div>
                <div className="checkbox-container">
                  <div className="checkboxsize-special">
                    {diabetesType.map((type) => (
                      <Checkbox
                        disabled={
                          isSelected && isSelected !== type ? true : false
                        }
                        name={type}
                        key={type}
                        onChange={onChange}
                        className="special-checkbox">
                        {type}
                      </Checkbox>
                    ))}
                  </div>
                </div>{" "}
                <div className="errormessage">
                  {diabetesTypeError ? diabetesTypeError : null}
                </div>
              </div>
              <div>
                <div className="question-tiitle">
                  Diabetes Treatment<span className="red">*</span>
                </div>
                <div></div>
                <div className="checkbox-container">
                  <div className="checkboxsize">
                    {" "}
                    <Checkbox
                      onChange={(e) => setInsulinBasalBolus(e.target.checked)}>
                      {" "}
                      Insulin: Basal/Bolus{" "}
                    </Checkbox>
                  </div>
                  <div className="checkboxsize">
                    {" "}
                    <Checkbox
                      onChange={(e) => setInsulinPremix(e.target.checked)}>
                      {" "}
                      Insulin: Premix{" "}
                    </Checkbox>
                  </div>
                </div>
                <div className="checkbox-container">
                  <div className="checkboxsize">
                    {" "}
                    <Checkbox
                      onChange={(e) => setInsulinBasalOnly(e.target.checked)}>
                      {" "}
                      Insulin: Basal Only{" "}
                    </Checkbox>
                  </div>
                  <div className="checkboxsize">
                    {" "}
                    <Checkbox
                      onChange={(e) => setInsulinPump(e.target.checked)}>
                      {" "}
                      Insulin-Pump{" "}
                    </Checkbox>
                  </div>
                </div>
                <div className="checkbox-container">
                  <div className="checkboxsize">
                    {" "}
                    <Checkbox
                      onChange={(e) => setOralMedication(e.target.checked)}>
                      {" "}
                      Oral Medication{" "}
                    </Checkbox>
                  </div>
                  <div className="checkboxsize">
                    {" "}
                    <Checkbox
                      onChange={(e) =>
                        setInsulinOralMedication(e.target.checked)
                      }>
                      {" "}
                      Insulin &amp; Oral Medication{" "}
                    </Checkbox>
                  </div>
                </div>
                <div className="checkbox-container">
                  <div className="checkboxsize">
                    {" "}
                    <Checkbox
                      onChange={(e) => setOthersClick(e.target.checked)}>
                      {" "}
                      Others{" "}
                    </Checkbox>
                    {OthersClick ? (
                      <Input
                        value={Others}
                        onChange={(e) => setOthers(e.target.value)}
                        className="question-input"
                        placeholder="Others"></Input>
                    ) : null}
                  </div>
                </div>
                <div className="errormessage">
                  {diabetesTreatmentError ? diabetesTreatmentError : null}
                </div>
              </div>
              <div className="question-tiitle-two">
                Where do you go for your diabetes management?
              </div>
              <div>
                <div className="question-tiitle">
                  Name of Hospital/Clinic<span className="red">*</span>
                  {
                    storecode && storecode.includes("FSLPhy") ? (
                      <Select onChange={handleHospital} value={hospitalName} required>
                        {
                          storelist.map(store => {
                            return (<Option key={store._id} value={store.channel}>{store.channel}</Option>)
                          })
                        }
                      </Select>
                    ) : (
                      <Input
                        className="question-input"
                        value={hospitalName}
                        onChange={(e) => sethospitalName(e.target.value)}
                        required></Input>
                    )
                  }
                  <div className="errormessage">
                    {hospitalNameError ? hospitalNameError : null}
                  </div>
                </div>
              </div>
              <div>
                <div className="question-tiitle">
                  Name of Doctor<span className="red">*</span>{" "}
                  <Input
                    className="question-input"
                    value={doctorName}
                    onChange={(e) => setdoctorName(e.target.value)}
                    required></Input>
                  <div className="errormessage">
                    {doctorNameError ? doctorNameError : null}
                  </div>
                </div>
              </div>

              <div className="ok-button-container">
                <div className="errormessage">{error ? error : null}</div>

                <Button
                  className="ok-button"
                  loading={loading}
                  htmlType="submit">
                  {" "}
                  NEXT
                </Button>
              </div>
            </div>
          </Form>
        </div>
      ) : null}
      <Modal open={modal} centered header={null} footer={null} closable={false}>
        <Form onFinish={clickbox}>
          <div className="modal-message">
            <div className="modal-inside">
              <div className="modal-image-container">
                <img src={freestyle} alt="" className="modal-image"></img>
              </div>
              <div className="modal-header-css">CONSENT</div>
              <div className="modal-contain">
                Abbott Laboratories (Malaysia) Sdn Bhd (“Abbott”) is collecting
                your personal information in order to phone, email SMS and/or
                mail to you helpful information about diabetes and its related
                treatments, products and services, and for marketing and
                promotional purposes. We may also share your information with
                our affiliates and subsidiaries as well as authorized third
                parties who may assist us in collecting and processing
                information. The information provided by you may be transferred
                to other countries for processing and storage purpose. We will
                not sell your personal received by us will be used in accordance
                with our Privacy Policy and in compliance with local law.
              </div>
              <div className="modal-contain">
                Abbott will not use your personal data in the above mentioned
                direct marketing unless we have received your consent
              </div>
              <div>
                <Form.Item name="agreement">
                  {
                    storecode && storecode.includes("FSLPhy") ? (
                      <Checkbox checked={checked} onChange={e => setChecked(e.target.checked)}>
                        I hereby consent to Abbott’s collection and use of my
                        personal information provided in this form for marketing
                        purposes in accordance with its{" "}
                        <a
                          onClick={() => setTncModal(true)}
                          style={{ color: "blue" }}>
                          Terms and Conditions{" "}
                        </a>
                        and Privacy Policy and I further authorise Abbott to contact
                        me by mail, email, phone and SMS regarding diabetes and its
                        related treatments, products and services.
                      </Checkbox>
                    ) : (
                      <Checkbox required>
                        I hereby consent to Abbott’s collection and use of my
                        personal information provided in this form for marketing
                        purposes in accordance with its{" "}
                        <a
                          onClick={() => setTncModal(true)}
                          style={{ color: "blue" }}>
                          Terms and Conditions{" "}
                        </a>
                        and Privacy Policy and I further authorise Abbott to contact
                        me by mail, email, phone and SMS regarding diabetes and its
                        related treatments, products and services.
                      </Checkbox>
                    )
                  }
                </Form.Item>
              </div>
            </div>
          </div>
          <div>
            <div className="errormessage">{error ? error : null}</div>
            <Button
              style={{ width: "100%" }}
              className="ok-button-two"
              htmlType="submit"
              loading={loading}>
              DONE
            </Button>
          </div>
        </Form>
      </Modal>

      <Modal
        open={tncmodal}
        centered
        header={null}
        footer={null}
        closable={false}>
        <Form onFinish={clickbox}>
          <div className="modal-message">
            <div className="modal-inside">
              <div className="modal-header-css">TERM AND CONDITIONS</div>
              <div className="modal-contain">
                <ul>
                  <li>
                    {" "}
                    Only new users of FreeStyle Libre are eligible to
                    participate in this program. Existing or past users of
                    FreeStyle Libre products are not eligible to join the
                    program.
                  </li>
                  <li>
                    Each eligible participant would need to purchase 1 Starter
                    Kit (1 Reader and 1 Sensor), attend a {storecode && storecode.includes("FSLPhy") ? "Education " : "Group Education "}
                    session and upload the receipt in order to receive 1
                    FreeStyle Libre sensor free of charge.
                  </li>
                  <li>
                    Abbott reserves the right to verify the eligibility of
                    participants of the program.
                  </li>
                  <li>
                    To the extend permissible by law, Abbott shall not be
                    responsible for nay loss or damage sustained by a
                    participant while participating in the program, unless such
                    loss or damage is proven to be solely and directly caused by
                    Abbott’s negligence or willful default.
                  </li>
                  <li>
                    Abbott reserves the right in its absolute discretion to
                    vary, delete or add to any of these terms and conditions, or
                    to extend, modify, suspend or terminate the program at any
                    time without prior notice.
                  </li>
                </ul>
              </div>
              <div className="modal-contain">
                The FreeStyle Libre flash glucose monitoring system is indicated
                for measuring interstitial fluid glucose levels in people (age 4
                and older) with diabetes mellitus. The indication for children
                (age 4 – 17) is limited to those who are supervised by a
                caregiver at least 18 years old who is responsible for
                supervising, managing, and assisting the child in using the
                FreeStyle Libre system and interpreting its readings. Health
                care professionals and consumers should be aware about the
                limitations of available scientific evidence for use of this
                device in any other groups of patients who require diabetes
                management. Always read the label and use as directed.
              </div>
            </div>
          </div>
          <div>
            <div className="errormessage">{error ? error : null}</div>
            <Button
              style={{ width: "100%" }}
              className="ok-button-two"
              onClick={() => setTncModal(false)}>
              OK
            </Button>
          </div>
        </Form>
      </Modal>
      <Modal
        open={initialModal}
        centered
        header={null}
        footer={null}
        closable={null}
      >
        <div style={{ textAlign: "center" }}>
          <img
            src={tick1}
            style={{
              marginBottom: "5vh",
              width: "100px",
              height: "100px",
            }}
            alt=""
          ></img>
          <h1 style={{ fontWeight: "bold" }}>Success!</h1>

          <div style={{ marginBottom: "3vh" }}>
            You are eligible for a free sensor redemption. Kindly fill in your details.
          </div>

          <Button
            className="ok-button-two"
            style={{ width: "50%" }}
            onClick={() => setInitialModal(false)}
          >
            OK
          </Button>
        </div>
      </Modal>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    storecode: state.storecode,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateAddress: data => dispatch({ type: "UPDATE_ADDRESS", payload: data })
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupEducation);
