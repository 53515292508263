import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
    Button,
    Checkbox,
    Input,
    DatePicker,
    Select,
    Form,
    Modal,
} from "antd";
import axios from "axios";
import { connect } from 'react-redux';

import tick1 from "../assets/image/tick1.png";
import freestyle from "../assets/image/freestyle_logo.png";
import { checkExistingEmailNPhone } from "../services/email.service";

const { Option } = Select;

const PhysicalRegister = ({ storecode, user, token, userLogin, updateTransaction }) => {

    const navigate = useNavigate();
    const uploadimage = useRef();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");

    const [gender, setGender] = useState("");
    const [errorGender, setErrorGender] = useState("");

    // const [dob, setDob] = useState("");
    // const [doberror, setDobError] = useState("");

    const [firstName, setFirstName] = useState("");
    const [errorfirstname, setErrorFirstName] = useState("");

    const [lastName, setLastName] = useState("");
    const [errorlastname, setErrorlastName] = useState("");

    const [email, setEmail] = useState("");
    const [errorEmail, setErrorEmail] = useState("");

    const [diabetesType /*, setdiabetesType*/] = useState(["Type 1", "Type 2"]);
    const [diabetesTypeError, setdiabetesTypeError] = useState("");
    const [isSelected, setIsSelected] = useState("");

    const [hospitalName, sethospitalName] = useState("");
    const [hospitalNameError, sethospitalNameError] = useState("");

    const [doctorName, setdoctorName] = useState("");
    const [doctorNameError, setdoctorNameError] = useState("");

    const [consentModal, setConsentModal] = useState(false);
    const [tncModal, setTncModal] = useState(false);
    const [initialModal, setInitialModal] = useState(true);

    const [storelist, setStoreList] = useState([]);

    const [checked, setChecked] = useState(true);

    const [file, setFile] = useState(null)

    useEffect(() => {
        if (!user) {
            if (token) {
                setLoading(true)
                axios
                    .post("/api/auth/checkToken", { token })
                    .then((data) => {
                        userLogin({ user: data.data.user, token: data.data.token })
                        setLoading(false);
                        if (data.data.user.transactions.length > 0) {
                            navigate("/redemptionComplete");
                        }
                    })
                    .catch((err) => {
                        navigate("/welcome")
                    });
            } else {
                navigate("/welcome")
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        axios.get("/api/auth/getStorelist")
            .then((res) => {
                if (storecode && storecode.includes("FSLPhy")) {
                    let channel = res.data.data.filter(store => store.storecode === storecode)[0]
                    if (channel) {
                        sethospitalName(channel.channel)
                    }
                }
                setStoreList(res.data.data);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                setError(err.response.data.error);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const submit = async () => {
        const regEmail = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;
        const regName = /^[a-zA-Z ]+( [a-zA-Z ]+)*$/;

        setLoading(true);
        setError("");
        setErrorGender("");
        // setDobError("");
        setErrorFirstName("");
        setErrorlastName("");
        setErrorEmail("");
        setdiabetesTypeError("")
        sethospitalNameError("")
        setdoctorNameError("")

        const emailNPhoneExist = await checkExistingEmailNPhone({email})

        if (!firstName) {
            setErrorFirstName("Please fill in your first name")
            setLoading(false)
        } else if (!lastName) {
            setErrorlastName("Please fill in your last name")
            setLoading(false)
        } else if (!gender) {
            setErrorGender("Please choose your gender");
            setLoading(false);
        }
        // else if (!dob) {
        //     setDobError("Please fill in date of birth")
        //     setLoading(false)
        // }
        else if (!hospitalName) {
            sethospitalNameError("Please select the Hospital Name")
            setLoading(false)
        } else if (!file) {
            setError("Please upload receipt")
            setLoading(false)
        } else if (email && !regEmail.test(email)) {
            setErrorEmail("Invalid email format");
            setLoading(false)
        } else if (!regName.test(firstName)) {
            setErrorFirstName("Invalid name format")
            setLoading(false)
        } else if (!regName.test(lastName)) {
            setErrorlastName("Invalid name format")
            setLoading(false)
        } else if (emailNPhoneExist.exist){
            setError(emailNPhoneExist.message); 
            setLoading(false);
        }
        //  else if (new Date().getFullYear() - new Date(dob).getFullYear() < 18) {
        //     setDobError("Only 18 years old and above can join the campaign");
        //     setLoading(false)
        // }
        else {
            const formData = new FormData();
            formData.append("file", file);
            formData.append("firstName", firstName);
            formData.append("lastName", lastName);
            formData.append("gender", gender);
            // formData.append("dob", dob);

            if(email){
                formData.append("email", email);
            }

            formData.append("handphone", user.number);
            formData.append("diabetesType", isSelected);
            formData.append("hospitalName", hospitalName);
            formData.append("doctorName", doctorName);
            formData.append("storecode", storecode);

            axios.post("/api/auth/physicalRegister", formData)
                .then(res => {
                    updateTransaction(res.data.data);
                    setLoading(false)
                    navigate("/redemption", { state: { redeem: true } })
                })
                .catch(err => {
                    setLoading(false);
                    setError(err.response.data.error);
                })
        }
    }

    const isEmail = (e) => {
        setEmail(e.target.value);

        // eslint-disable-next-line no-useless-escape
        let regEmail = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;
        if (!regEmail.test(e.target.value)) {
            setErrorEmail("Invalid email format");
        } else {
            setErrorEmail("");
        }
        if (!e.target.value) {
            setErrorEmail("");
        }
    };

    const isfirstName = (e) => {
        var regex = /^[a-zA-Z ]+( [a-zA-Z ]+)*$/;
        // /^[a-zA-Z]+(?:\s[a-zA-Z]+)+$/;
        setFirstName(e.target.value);
        if (!regex.test(e.target.value)) {
            setErrorFirstName("Invalid name format");
        } else {
            setErrorFirstName("");
        }
        if (!e.target.value) {
            setErrorFirstName("");
        }
    };

    const islastName = (e) => {
        var regex = /^[a-zA-Z ]+( [a-zA-Z ]+)*$/;
        // /^[a-zA-Z]+(?:\s[a-zA-Z]+)+$/;
        setLastName(e.target.value);
        if (!regex.test(e.target.value)) {
            setErrorlastName("Invalid name format");
        } else {
            setErrorlastName("");
        }
        if (!e.target.value) {
            setErrorlastName("");
        }
    };

    const handleGender = (value) => {
        setGender(value);
    };

    const handleHospital = (value) => {
        sethospitalName(value);
    };

    // const isDob = (date, dateString) => {
    //     setDobError("");
    //     if (new Date().getFullYear() - new Date(dateString).getFullYear() < 18) {
    //         setDobError("Only 18 years old and above can join the campaign");
    //         setDob("");
    //     } else {
    //         setDob(dateString);
    //     }
    // };

    const onChange = (e) => {
        if (e.target.checked) {
            setIsSelected(e.target.name);
        } else {
            setIsSelected("");
        }
    };

    const handleImage = (e) => {

        const image = e.target.files[0];

        const jpgRegex = /.jpg/g;
        const pngRegex = /.png/g;
        const jpegRegex = /.jpeg/g;
        const pdfRegex = /.pdf/g;

        if (image) {
            if (jpgRegex.test(image.name) || pngRegex.test(image.name) || jpegRegex.test(image.name) || pdfRegex.test(image.name)) {
                setFile(image)
            } else {
                setError("Please only use pdf, png, jpg or jpeg file extension type")
            }
        }
    }

    return (
        <div>
            <div className="header-icon-position">
                <img src={freestyle} alt="" className="header-icon-size" />
            </div>
            <div className="header" style={{ fontFamily: "Brandon Grotesque Bold" }}>Education Session</div>
            <div className="malaysia-container">
                <div className="malaysia">Country: Malaysia</div>
            </div>
            <div>
                <div className="personal-info">REGISTRATION</div>
                <div className="hinttext">
                    Mandatory fields are marked with<span className="red">*</span>
                </div>
                <Form onFinish={submit}>
                    <div className="form-container">
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div style={{ width: "45%" }}>
                                <div className="question-tiitle">
                                    <span style={{ fontFamily: "Brandon Grotesque Bold" }}>First Name<span className="red">*</span></span>
                                </div>
                                <Input
                                    className="question-input"
                                    value={firstName}
                                    onChange={(e) => isfirstName(e)}
                                    required
                                />
                                <div className="errormessage">
                                    {errorfirstname ? errorfirstname : null}
                                </div>
                            </div>
                            <div style={{ width: "45%" }}>
                                <div className="question-tiitle">
                                    <span style={{ fontFamily: "Brandon Grotesque Bold" }}>Last Name<span className="red">*</span></span>
                                </div>
                                <Input
                                    className="question-input"
                                    value={lastName}
                                    onChange={(e) => islastName(e)}
                                    required
                                />
                                <div className="errormessage">
                                    {errorlastname ? errorlastname : null}
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="question-tiitle">
                                <span style={{ fontFamily: "Brandon Grotesque Bold" }}>Gender<span className="red">*</span></span>
                                <Select onChange={handleGender} value={gender} required>
                                    <Option value="Male">Male</Option>
                                    <Option value="Female">Female</Option>
                                </Select>
                            </div>
                            <div className="errormessage">
                                {errorGender ? errorGender : null}
                            </div>
                        </div>
                        {/* <div>
                            <div className="question-tiitle">
                                Date of Birth (YYYY-MM-DD)<span className="red">*</span>
                            </div>
                            <DatePicker
                                className="question-input"
                                required
                                onChange={isDob}
                            />
                            <div className="errormessage">{doberror ? doberror : null}</div>
                        </div> */}
                        <div>
                            <div className="question-tiitle">
                                <span style={{ fontFamily: "Brandon Grotesque Bold" }}>Email</span>
                            </div>
                            <Input
                                className="question-input"
                                onChange={(e) => isEmail(e)}
                                value={email}
                                placeholder="Example: abc@gmail.com"
                            />
                            <div className="errormessage">
                                {errorEmail ? errorEmail : null}
                            </div>
                        </div>
                        <div>
                            <div className="question-tiitle">
                                <span style={{ fontFamily: "Brandon Grotesque Bold" }}>Diabetes Type</span>
                            </div>
                            <div className="checkbox-container">
                                <div className="checkboxsize-special">
                                    {diabetesType.map((type) => (
                                        <Checkbox
                                            disabled={isSelected && isSelected !== type ? true : false}
                                            name={type}
                                            key={type}
                                            onChange={onChange}
                                            className="special-checkbox">
                                            {type}
                                        </Checkbox>
                                    ))}
                                </div>
                            </div>
                            <div className="errormessage">
                                {diabetesTypeError ? diabetesTypeError : null}
                            </div>
                        </div>
                        <div className="question-tiitle-two">
                            <span style={{ fontFamily: "Brandon Grotesque Bold" }}>Where do you go for your diabetes management?</span>
                        </div>
                        <div>
                            <div className="question-tiitle">
                                <span style={{ fontFamily: "Brandon Grotesque Bold" }}>Name of Hospital/Clinic<span className="red">*</span></span>
                                <Select onChange={handleHospital} value={hospitalName} required>
                                    {
                                        storelist.map(store => {
                                            return (<Option key={store._id} value={store.channel}>{store.channel}</Option>)
                                        })
                                    }
                                </Select>
                                <div className="errormessage">
                                    {hospitalNameError ? hospitalNameError : null}
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="question-tiitle">
                                <span style={{ fontFamily: "Brandon Grotesque Bold" }}>Name of Doctor</span>
                                <Input
                                    className="question-input"
                                    value={doctorName}
                                    onChange={(e) => setdoctorName(e.target.value)}
                                />
                                <div className="errormessage">
                                    {doctorNameError ? doctorNameError : null}
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="upload-input-title" style={{ paddingLeft: "0", fontFamily: "Brandon Grotesque Bold" }}>Upload Image<span className="red">*</span></div>
                            <div className="upload-container" style={{ width: "100%", margin: "0" }}>
                                <div onClick={e => uploadimage.current.click()}>
                                    <input
                                        onChange={handleImage}
                                        type="file"
                                        accept="application/pdf, image/png, image/jpeg, image/jpg"
                                        ref={uploadimage}
                                        hidden
                                    />
                                    {file ? (
                                        <div className="uploadimage-box-uploaded">
                                            {file.type.split("/")[1] === "pdf" ? (
                                                <p>{file.name}</p>
                                            ) : (
                                                <img
                                                    style={{ width: "100%", height: "auto" }}
                                                    src={URL.createObjectURL(file)}
                                                    alt=""
                                                />
                                            )}
                                        </div>
                                    ) : (
                                        <div className="uploadimage-box">
                                            <button className="uploadimage-box-font" type="button">
                                                Upload receipt photo
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div style={{ paddingTop: "5%" }}>
                            <Checkbox checked={checked} onChange={e => setChecked(e.target.checked)}>
                                I hereby <span style={{ color: "#0D6EFD" }} onClick={() => setConsentModal(true)}><u>consent</u></span> to Abbott’s collection and use of my personal information provided in this form for marketing purposes in accordance with its <span style={{ color: "#0D6EFD" }} onClick={() => setTncModal(true)}><u>Terms and Conditions</u></span> and <a href="https://www.my.abbott/privacy-policy.html" target="_blank" rel="noreferrer">Privacy Policy</a> and I further authorise Abbott to contact me by mail, email, phone and SMS regarding diabetes and its related treatments, products and services.
                            </Checkbox>
                        </div>
                        <div className="ok-button-container" style={{ paddingTop: "10%" }}>
                            <div className="errormessage">{error ? error : null}</div>
                            <Button
                                className="ok-button"
                                loading={loading}
                                htmlType="submit">
                                NEXT
                            </Button>
                        </div>
                    </div>
                </Form>
            </div>
            <Modal
                open={initialModal}
                centered
                header={null}
                footer={null}
                closable={null}
            >
                <div style={{ textAlign: "center" }}>
                    <img
                        src={tick1}
                        style={{
                            marginBottom: "5vh",
                            width: "100px",
                            height: "100px",
                        }}
                        alt=""
                    ></img>
                    <h1 style={{ fontWeight: "bold" }}>Success!</h1>

                    <div style={{ marginBottom: "3vh", fontSize: "18px" }}>
                        You are eligible for a free sensor redemption. Kindly fill in your details.
                    </div>

                    <Button
                        className="ok-button-two"
                        style={{ width: "50%" }}
                        onClick={() => setInitialModal(false)}
                    >
                        OK
                    </Button>
                </div>
            </Modal>
            <Modal open={consentModal} centered header={null} footer={null} closable={false}>
                <div>
                    <div style={{ textAlign: "center" }}>
                        <img src={freestyle} alt="" className="modal-image"></img>
                    </div>
                    <div className="modal-header-css" style={{ fontFamily: "Brandon Grotesque Bold" }}>CONSENT</div>
                    <div className="modal-contain">
                        Abbott Laboratories (Malaysia) Sdn Bhd (“Abbott”) is collecting your personal information in order to phone, email SMS and/or mail to you helpful information about diabetes and its related treatments, products and services, and for marketing and promotional purposes. We may also share your information with our affiliates and subsidiaries as well as authorized third parties who may assist us in collecting and processing information. The information provided by you may be transferred to other countries for processing and storage purpose. We will not sell your personal information received and it will be used in accordance with our Privacy Policy and in compliance with local law.
                    </div>
                    <div className="modal-contain">
                        Abbott will not use your personal data in the above mentioned direct marketing unless we have received your consent.
                    </div>
                    <div className="ok-button-container">
                        <Button
                            className="ok-button"
                            htmlType="button"
                            onClick={() => setConsentModal(false)}
                        >
                            BACK
                        </Button>
                    </div>
                </div>
            </Modal>
            <Modal open={tncModal} centered header={null} footer={null} closable={false}>
                <div>
                    <div style={{ textAlign: "center" }}>
                        <img src={freestyle} alt="" className="modal-image"></img>
                    </div>
                    <div className="modal-header-css" style={{ fontFamily: "Brandon Grotesque Bold" }}>TERMS AND CONDITIONS</div>
                    <div className="modal-contain">
                        <ul>
                            <li>Only new users of FreeStyle Libre are eligible to participate in this program. Existing or past users of FreeStyle Libre products are not eligible to join the program.</li>
                            <li>Each eligible participant would need to purchase 1 Reader, attend an Education session and upload the receipt in order to receive 1 FreeStyle Libre sensor free of charge.</li>
                            <li>Abbott reserves the right to verify the eligibility of participants of the program.</li>
                            <li>To the extent permissible by law, Abbott shall not be responsible for any loss or damage sustained by a participant while participating in the program, unless such loss or damage is proven to be solely and directly caused by Abbott’s negligence or willful default.</li>
                            <li>Abbott reserves the right in its absolute discretion to vary, delete or add to any of these terms and conditions, or to extend, modify, suspend or terminate the program at any time without prior notice.</li>
                        </ul>
                        <div className="modal-contain">The FreeStyle Libre flash glucose monitoring system is indicated for measuring interstitial fluid glucose levels in people (age 4 and older) with diabetes mellitus. The indication for children (age 4 – 17) is limited to those who are supervised by a caregiver at least 18 years old who is responsible for supervising, managing, and assisting the child in using the FreeStyle Libre system and interpreting its readings. Health care professionals and consumers should be aware about the limitations of available scientific evidence for use of this device in any other groups of patients who require diabetes management. Always read the label and use as directed.</div>
                    </div>
                    <div className="ok-button-container">
                        <Button
                            className="ok-button"
                            htmlType="button"
                            onClick={() => setTncModal(false)}
                        >
                            OK
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        storecode: state.storecode,
        user: state.user,
        token: state.token
    };
};

const mapDispatchToProps = dispatch => {
    return {
        userLogin: data => dispatch({ type: "USER_LOGIN", payload: data }),
        updateTransaction: data => dispatch({ type: "UPDATE_TRANSACTION", payload: data })
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PhysicalRegister);