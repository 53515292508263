import axios from 'axios';

const checkExistingEmailNPhone = async ({email, phone}) => {
  try {
    const response = await axios.post("/api/auth/checkExistingEmailNPhone", { email,  phone });

    return response.data;
  } catch (error) {
    throw error; 
  }
};


export { checkExistingEmailNPhone };